import React from "react";
import { graphql } from "gatsby";
import TwoColumnLayout from "../components/layout/TwoColumnLayout";
import Sidebar from "../components/routes/Home/NewsAndEvents/Sidebar";
import PageHero from "../components/common/PageHero";
import ModernFeed from "../components/routes/Home/NewsAndEvents/NewsFeed/ModernFeed";
import { NewsExcerptFields } from "./fragments/NodeNewsExcerptFields";
import { NewsPost } from "../classes/News";
import { Helmet } from "react-helmet";

export default ({
  data,
  pageContext
}: {
  data: TagQuery;
  pageContext: TagContext;
}) => {
  const { tag } = pageContext;
  const news = data.news.edges.map((e) => new NewsPost(e.node));
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>

      <PageHero title={"Archives"} />
      <TwoColumnLayout
        Main={() => <ModernFeed posts={news} title={"Tag: " + tag} />}
        Sidebar={Sidebar}
      />
    </>
  );
};

interface TagContext {
  tag: string;
}

interface TagQuery {
  news: {
    edges: { node: NewsExcerptFields }[];
  };
}

export const query = graphql`
  query newsTagQuery($tag: [String]) {
    news: allContentfulNews(
      filter: { tags: { in: $tag }, visibility: { in: "UTRWD" } }
    ) {
      edges {
        node {
          ...NodeNewsExcerptFields
        }
      }
    }
  }
`;
